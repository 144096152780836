@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;
@tailwind typography;
@tailwind aspect-ratio;

#app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
#router-container {
  flex: 1;    /* same as flex-grow: 1; */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.gradient-bg {
  background-color:#0f0e13;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:
    radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%),
    radial-gradient(at 50% 0%, hsla(225,39%,30%,1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(339,49%,30%,1) 0, transparent 50%);
}

.gradient-bg-services {
  background-color:#0f0e13;
  background-image:
    radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%),
    radial-gradient(at 50% 100%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-transactions {
  background-color: #0f0e13;
  background-image:
    radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 50%),
    radial-gradient(at 50% 0%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-footer {
  background-color: #0f0e13;
  background-image:
    radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 53%),
    radial-gradient(at 50% 150%, hsla(339,49%,30%,1) 0, transparent 50%);
}

.blue-glassmorphism {
  background: rgb(39, 51, 89, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* white glassmorphism */
.white-glassmorphism {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.eth-card {
  background-color:#a099ff;
  background-image:
    radial-gradient(at 83% 67%, rgb(152, 231, 156) 0, transparent 58%),
    radial-gradient(at 67% 20%, hsla(357,94%,71%,1) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsla(222,81%,65%,1) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsla(9,61%,61%,1) 0, transparent 52%),
    radial-gradient(at 27% 71%, hsla(336,91%,65%,1) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsla(30,98%,65%,1) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsla(174,94%,68%,1) 0, transparent 45%);
}

a.underlined, a:hover.underlined, a:visited.underlined, a:active.underlined {
  text-decoration: #8275a9 !important;
  -webkit-text-decoration: #8275a9 wavy underline !important;
  color: #8275a9 !important;
}

.text-gradient {
  background-color: #fff;
  background-image: radial-gradient(at 4% 36%, hsla(0,0%,100%,1) 0, transparent 53%), radial-gradient(at 100% 60%, rgb(0, 0, 0) 0, transparent 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@font-face {
  font-family: 'Tiempostext';
  src: url('/assets/fonts/TiemposText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fa brands 400';
  src: url('/assets/fonts/fa-brands-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fa 400';
  src: url('/assets/fonts/fa-regular-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fa solid 900';
  src: url('/assets/fonts/fa-solid-900.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  scroll-behavior: smooth;
  color: #120747;
  //font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
}

footer-component {
  //margin-top: 35px;
}

.icon-container {
  font-family: 'Fa solid 900', sans-serif;
}

.icon-container-2 {
  font-family: "Fa brands 400", sans-serif;
}

h1, h2, h3 {
  font-family: Tiempostext, sans-serif;
}

h3 {
  line-height: 30px;
  margin-top: 20px;
}

h3.heading {
  margin-bottom: 20px;
  font-family: Tiempostext, sans-serif;
  color: #120747;
  font-size: 30px;
}

.font-title {
  font-family: Tiempostext, sans-serif;
}

.rounded-input {
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  vertical-align: middle;
  border: 1px solid #cccccc;
  height: 60px;
  border-style: none;
  border-width: 2px;
  border-color: #cec5ec !important;
  border-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #f7f5ff;
  font-size: 20px;
}

.rounded-input:hover, .rounded-input:focus {
  box-shadow: 0 0 0pt 2pt #e7e1ff !important;
  outline-style: hidden;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.icon-font {
  font-family: 'Fa solid 900', sans-serif;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.accepted-checkbox {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.CybotCookiebotDialogBodyButton {
  border-radius: 35px !important;
}

.checkbox-label {
  font-size: 16px;
}

.checkbox {
  width: 30px;
  height: 30px;
  margin-top: 0px;
  margin-right: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 20px;
}

.checkbox.accepted-checkbox {
  background-color: #43a544;
  background-size: 20px 20px;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.color-secondary {
  color: #8275a9;

  a.underlined {
    -webkit-text-decoration-color: #8275a9; /* safari still uses vendor prefix */
    text-decoration-color: #8275a9;
  }
}

/*
.nav-link-special {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #ffe77e;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(.702, -.005, .158, 1.019);
  transition: -webkit-transform 200ms cubic-bezier(.702, -.005, .158, 1.019);
  transition: transform 200ms cubic-bezier(.702, -.005, .158, 1.019);
  transition: transform 200ms cubic-bezier(.702, -.005, .158, 1.019), -webkit-transform 200ms cubic-bezier(.702, -.005, .158, 1.019);
  font-weight: 700;
}

.nav-link-special:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-link-special:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.nav-link-special.w--current {
  color: #120747;
}

.nav-link-special.form-btn {
  position: absolute;
  left: auto;
  top: auto;
  right: 49px;
  bottom: -36px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  padding-top: 18px;
  padding-bottom: 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 5px;
  border-color: #fff;
  color: #120747;
  font-size: 23px;
  text-decoration: none;
}

.nav-link-special.form-btn.como_funciona {
  position: static;
  margin-top: 30px;
  margin-right: auto;
  margin-left: 0px;
  border-width: 2px;
  border-color: #cec5ec;
  background-color: rgba(255, 231, 126, 0);
}

 */

.heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Tiempostext, sans-serif;
  font-size: 58px;
  line-height: 60px;
}

.heading-second-line {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Tiempostext, sans-serif;
  font-size: 82px;
  line-height: 88px;
  font-weight: 700;
}

.btn-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 35px;
  border-radius: 30px;
  background-color: #120747;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(.725, -.005, .25, 1);
  transition: -webkit-transform 200ms cubic-bezier(.725, -.005, .25, 1);
  transition: transform 200ms cubic-bezier(.725, -.005, .25, 1);
  transition: transform 200ms cubic-bezier(.725, -.005, .25, 1), -webkit-transform 200ms cubic-bezier(.725, -.005, .25, 1);
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}

.btn-2:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}


.prefooter-image {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 100%;
}

body.on-modal {
  height: 100vh;
  overflow-y: hidden;
}

.dot {
  background-color: rgb(255,255,255);
  float: left;
  height: 7px;
  margin-left: 4px;
  width: 7px;
  animation-name: bounce_dot;
  animation-duration: 2.24s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  border-radius: 5px;
}
.dot_1 { animation-delay: 0.45s; }
.dot_2 { animation-delay: 1.05s; }
.dot_3 { animation-delay: 1.35s; }

.popup-modal {

  .scrollable-content-container, .scrollable-items-container {
    margin-left: -25px;
    margin-right: -25px;
  }

  .scrollable-content {
    padding-left: 25px;
    padding-right: 25px;
  }

  .main-container {
    min-width: 400px;
  }

  .list-container {
    height: 26rem;
  }

  .scrollable-items-container {
    height: calc(100% - 20px);
  }

  @media screen and (max-width: 479px) {

    .main-container {
      min-width: inherit;
    }

    .scrollable-items-container {
      height: calc(100dvh - 97px);
    }

  }

}

.dev-height {
  height: auto;
}

.z-max {
  z-index: 1500;
}

@keyframes bounce_dot {
  0% {}
  50% { background-color: #8a8a8a; }
  100% {}
}

@media screen and (max-width: 767px) {

  .heading, .typed-title {
    font-size: 42px;
    line-height: 47px;
  }

}

@media screen and (max-width: 479px) {

  /*
  .nav-link-special.form-btn {
    position: absolute;
    top: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -70px;
  }

   */

  .dev-height {
    height: calc(100vh - env(safe-area-inset-top));
  }

  .prefooter-image {
    width: 200%;
    max-width: 200%;
  }

  .popup {
    width: 95%;
    border-radius: 0px !important;
  }

}

.popup {
  border-radius: 30px;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

/*
.app_container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-right: 0px;
  padding: 30px 65px 60px;
  border-radius: 30px;
  background-color: #fff;

  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}


.app_container.width-auto {
  width: 100%;
  margin-left: auto;
  padding-top: 15px;
}

 */

.border-ac {
  z-index: -2;
  --tw-border-opacity: 1;
  border-color: #8275a9;
  background-color: #eae4ff;
}

.ring-ac {
  --tw-ring-opacity: 1;
  --tw-ring-color: #8275a9;
}

.text-ac {
  --tw-text-opacity: 1;
  color: #8275a9;
}
